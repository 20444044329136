import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";
import map from "lodash/map";
import find from "lodash/find";
import filter from "lodash/filter";

import Header from "features/header";
import Button from "shared/components/Button";
import footballLogo from "shared/assets/icons/football_small.png";
import teamLogo from "shared/assets/icons/team.png";
import player1Logo from "shared/assets/icons/anderson.png";
import player2Logo from "shared/assets/icons/zabaleta.png";
import player3Logo from "shared/assets/icons/gabriel.png";
import player4Logo from "shared/assets/icons/sterling.png";
import player5Logo from "shared/assets/icons/bravo.png";
import changeIcon from "shared/assets/icons/change.png";
import playerReplacementIcon from "shared/assets/icons/player_replacement.png";
import soccerShirt from "shared/assets/icons/soccer_shirt.png";

const AppContainer = styled.div`
  height: calc(100vh);
  width: 100%;
  background: rgba(0,0,0,0.8);
  background-image: url("${require("shared/assets/icons/BG.png")}");
  background-size: cover;
  background-position: center top;
`;
const PageContainer = styled.div`
  height: calc(100vh - 110px);
  overflow-x: scroll;
  padding: 20px;
`;
const Footer = styled.div`
  height: 50px;
`;
const DetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
`;
const DetailsRightSection = styled.div`
  display: flex;
  align-items: center;
`;
const EventLogo = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 10px;
`;
const EventTime = styled.h2`
  color: white;
  border: 1px solid white;
  border-radius: 100%;
  font-size: 12px;
  padding: 2px;
  margin: 0;
`;
const ActorName = styled.h2`
  color: white;
  font-size: 12px;
  margin: 0;
`;

const DetailsLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const EventDate = styled.h2`
  color: white;
  font-size: 12px;
  margin: 0;
`;
const EventDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TeamLogo = styled.img`
  width: 20px;
  height: 20px;
`;
const EventDetail = styled.h2`
  color: white;
  font-size: 12px;
  font-family: Druk;
  margin: 0 10px;
`;

const PlayersWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-top: 40px;
  align-items: baseline;
  -webkit-overflow-scrolling: touch;
`;
const PlayerWrapper = styled.div`
  flex: 0 0 auto;
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PlayerName = styled.h2`
  color: white;
  font-size: ${props => (props.active ? "12px" : "10px")} !important;
  margin: 5px 0 0;
`;
const PlayingStyle = styled.h2`
  color: white;
  font-size: ${props => (props.active ? "10px" : "8px")} !important;
  margin: 0;
`;
const PlayerImageWrapper = styled.div`
  background: ${props => props.teamColor || "rgba(59, 190, 229, 1)"} !important;
  border-radius: 100%;
  width: ${props => (props.active ? "70px" : "40px")} !important;
  height: ${props => (props.active ? "70px" : "40px")} !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.5s, height 0.5s;
  transition-timing-function: ease-in;
`;
const PlayerImage = styled.img`
  width: ${props => (props.active ? "50px" : "30px")} !important;
  height: ${props => (props.active ? "50px" : "30px")} !important;
  transition: width 0.5s, height 0.5s;
  transition-timing-function: ease-in;
`;
const NextButton = styled(Button)`
  height: 100%;
`;

const PlayerComparisonWrapper = styled.div``;
const PlayerInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;
const HighlightedPlayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const HighlightedPlayerImageWrapper = styled.div`
  background: ${props => props.teamColor || "transparent"} !important;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HighlightedPlayerImage = styled.img`
  width: 70px;
  height: 70px;
`;
const HighlightedPlayerName = styled.h2`
  color: white;
  font-size: 12px;
  margin: 0;
`;
const ChangeIcon = styled.img`
  width: 24px;
  height: 24px;
`;
const PlayerStatsWrapper = styled.div``;
const StatWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;
const StatName = styled.h2`
  font-size: 18px;
  color: rgba(175, 175, 175, 1);
  margin: 0;
`;
const StatIndex = styled.div`
  height: 20px;
  width: 100%;
  background: rgba(180, 180, 180, 1);
  display: flex;
`;
const FirstPlayerData = styled.div`
  width: 50%;
  height: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-right: 1px solid rgba(180, 180, 180, 1);
`;
const FirstPlayerNumber = styled.div`
  font-size: 12px;
  color: white;
`;
const FirstPlayerLine = styled.div`
  width: ${props => (props.value ? `${props.value * 0.8}%` : "0")} !important;
  height: 20px;
  background-image: linear-gradient(
    to right,
    rgba(15, 230, 189, 1),
    rgba(4, 52, 43, 1)
  );
`;
const SecondPlayerData = styled.div`
  width: 50%;
  height: 20px;
  display: flex;
  align-items: center;
  border-left: 1px solid rgba(180, 180, 180, 1);
`;
const SecondPlayerNumber = styled.div`
  font-size: 12px;
  color: white;
`;
const SecondPlayerLine = styled.div`
  width: ${props => (props.value ? `${props.value * 0.8}%` : "0")} !important;
  height: 20px;
  background-image: linear-gradient(
    to right,
    rgba(9, 14, 46, 1),
    rgba(224, 228, 253, 1)
  );
`;

const players = [
  {
    id: 1,
    name: "BRAVO",
    number: "1",
    style: "Goalkeeper",
    image: player1Logo,
    teamColor: "rgba(59, 190, 229, 1)",
    stats: {
      shotsOnTarget: 43,
      shotsOffTarget: 68,
      blockedKicks: 22,
      posession: 89,
      cornerKicks: 4,
      fouls: 10
    }
  },
  {
    id: 2,
    name: "STERLING",
    number: "7",
    style: "Forward",
    image: player2Logo,
    teamColor: "rgba(59, 190, 229, 1)",
    stats: {
      shotsOnTarget: 22,
      shotsOffTarget: 80,
      blockedKicks: 70,
      posession: 55,
      cornerKicks: 87,
      fouls: 18
    }
  },
  {
    id: 3,
    name: "JESUS",
    number: "9",
    style: "Forward",
    image: player3Logo,
    teamColor: "rgba(59, 190, 229, 1)",
    stats: {
      shotsOnTarget: 25,
      shotsOffTarget: 65,
      blockedKicks: 87,
      posession: 46,
      cornerKicks: 90,
      fouls: 23
    }
  },
  {
    id: 4,
    name: "ZABALETA",
    number: "5",
    style: "Defender",
    image: player4Logo,
    teamColor: "rgba(124, 44, 59, 1)",
    stats: {
      shotsOnTarget: 85,
      shotsOffTarget: 12,
      blockedKicks: 43,
      posession: 53,
      cornerKicks: 56,
      fouls: 87
    }
  },
  {
    id: 5,
    name: "ANDERSON",
    number: "8",
    style: "Midfielder",
    image: player5Logo,
    teamColor: "rgba(124, 44, 59, 1)",
    stats: {
      shotsOnTarget: 12,
      shotsOffTarget: 23,
      blockedKicks: 87,
      posession: 43,
      cornerKicks: 59,
      fouls: 32
    }
  },
  {
    id: 6,
    name: "BRAVO",
    number: "1",
    style: "Goalkeeper",
    image: player1Logo,
    teamColor: "rgba(59, 190, 229, 1)",
    stats: {
      shotsOnTarget: 43,
      shotsOffTarget: 68,
      blockedKicks: 22,
      posession: 89,
      cornerKicks: 4,
      fouls: 10
    }
  },
  {
    id: 7,
    name: "STERLING",
    number: "7",
    style: "Forward",
    image: player2Logo,
    teamColor: "rgba(59, 190, 229, 1)",
    stats: {
      shotsOnTarget: 22,
      shotsOffTarget: 80,
      blockedKicks: 70,
      posession: 55,
      cornerKicks: 87,
      fouls: 18
    }
  },
  {
    id: 8,
    name: "JESUS",
    number: "9",
    style: "Forward",
    image: player3Logo,
    teamColor: "rgba(59, 190, 229, 1)",
    stats: {
      shotsOnTarget: 25,
      shotsOffTarget: 65,
      blockedKicks: 87,
      posession: 46,
      cornerKicks: 90,
      fouls: 23
    }
  },
  {
    id: 9,
    name: "ZABALETA",
    number: "5",
    style: "Defender",
    image: player4Logo,
    teamColor: "rgba(124, 44, 59, 1)",
    stats: {
      shotsOnTarget: 85,
      shotsOffTarget: 12,
      blockedKicks: 43,
      posession: 53,
      cornerKicks: 56,
      fouls: 87
    }
  },
  {
    id: 10,
    name: "ANDERSON",
    number: "8",
    style: "Midfielder",
    image: player5Logo,
    teamColor: "rgba(124, 44, 59, 1)",
    stats: {
      shotsOnTarget: 12,
      shotsOffTarget: 23,
      blockedKicks: 87,
      posession: 43,
      cornerKicks: 59,
      fouls: 32
    }
  }
];

const ReplayPage = ({ history, location }) => {
  const playersRef = useRef();
  const firstPlayer = find(
    players,
    player => player.id === location.state.selectedPlayer
  );
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const renderPlayers = () => {
    const onClickPlayer = player => {
      const scrollValues = [0, 0, 0, 75, 150, 225, 300, 375, 375, 375];
      playersRef.current.scrollLeft = scrollValues[player.id - 1];
      setSelectedPlayer(player);
    };

    return map(players, (player, index) => {
      return (
        <PlayerWrapper key={index} onClick={() => onClickPlayer(player)}>
          <PlayerImageWrapper
            active={selectedPlayer && selectedPlayer.id === player.id}
            teamColor={player.teamColor}
          >
            <PlayerImage
              active={selectedPlayer && selectedPlayer.id === player.id}
              src={player.image}
            />
          </PlayerImageWrapper>
          <PlayerName
            active={selectedPlayer === player.id}
          >{`#${player.number} ${player.name}`}</PlayerName>
          <PlayingStyle active={selectedPlayer === player.id}>
            {player.style}
          </PlayingStyle>
        </PlayerWrapper>
      );
    });
  };

  const onClickNext = () => {
    history.push({
      pathname: "/app/final",
      state: {
        firstPlayer,
        secondPlayer: selectedPlayer
      }
    });
  };
  return (
    <AppContainer>
      <Header
        leftSection={
          <ArrowLeftOutlined
            style={{ color: "#0ce6bd", fontSize: "16px" }}
            onClick={() => {
              history.push("/app/recreate");
            }}
          />
        }
        title="REPLAY THE HISTORY"
      />
      <PageContainer>
        <DetailsWrapper>
          <DetailsLeftSection>
            <EventDate>30 APRIL 1978</EventDate>
            <EventDetailsWrapper>
              <TeamLogo src={teamLogo} />
              <EventDetail>3 - 1</EventDetail>
              <TeamLogo src={teamLogo} />
            </EventDetailsWrapper>
          </DetailsLeftSection>
          <DetailsRightSection>
            <EventTime>89'</EventTime>
            <EventLogo src={footballLogo} />
            <ActorName>Jesus</ActorName>
          </DetailsRightSection>
        </DetailsWrapper>
        <PlayerComparisonWrapper>
          <PlayerInfoWrapper>
            <HighlightedPlayerWrapper>
              <HighlightedPlayerImageWrapper teamColor={firstPlayer.teamColor}>
                <HighlightedPlayerImage src={firstPlayer.image} />
              </HighlightedPlayerImageWrapper>
              <HighlightedPlayerName>{`#${firstPlayer.number} ${firstPlayer.name}`}</HighlightedPlayerName>
            </HighlightedPlayerWrapper>
            <ChangeIcon src={changeIcon} />
            {selectedPlayer ? (
              <HighlightedPlayerWrapper>
                <HighlightedPlayerImageWrapper
                  teamColor={selectedPlayer && selectedPlayer.teamColor}
                >
                  <HighlightedPlayerImage
                    src={
                      selectedPlayer
                        ? selectedPlayer.image
                        : playerReplacementIcon
                    }
                  />
                </HighlightedPlayerImageWrapper>
                <HighlightedPlayerName>
                  {selectedPlayer &&
                    `#${selectedPlayer.number} ${selectedPlayer.name}`}
                </HighlightedPlayerName>
              </HighlightedPlayerWrapper>
            ) : (
              <div
                style={{
                  position: "relative",
                  background: "rgba(0, 0, 0, 0.45)",
                  width: "100px",
                  height: "100px",
                  borderRadius: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    color: "white",
                    fontSize: "10px",
                    fontWeight: "bold",
                    fontFamily: "ProximaNova"
                  }}
                >
                  SELECT A PLAYER
                </span>
                <img
                  src={soccerShirt}
                  alt=""
                  width="70px"
                  height="70px"
                  style={{ opacity: "0.4" }}
                />
              </div>
            )}
          </PlayerInfoWrapper>
          <PlayerStatsWrapper>
            <StatWrapper>
              <StatName>SHOTS ON TARGET</StatName>
              <StatIndex>
                <FirstPlayerData>
                  <FirstPlayerLine value={firstPlayer.stats.shotsOnTarget} />
                  <FirstPlayerNumber>
                    {firstPlayer.stats.shotsOnTarget}
                  </FirstPlayerNumber>
                </FirstPlayerData>
                <SecondPlayerData>
                  <SecondPlayerLine
                    value={selectedPlayer && selectedPlayer.stats.shotsOnTarget}
                  />
                  <SecondPlayerNumber>
                    {selectedPlayer && selectedPlayer.stats.shotsOnTarget}
                  </SecondPlayerNumber>
                </SecondPlayerData>
              </StatIndex>
            </StatWrapper>

            <StatWrapper>
              <StatName>SHOTS OFF TARGET</StatName>
              <StatIndex>
                <FirstPlayerData>
                  <FirstPlayerLine value={firstPlayer.stats.shotsOffTarget} />
                  <FirstPlayerNumber>
                    {firstPlayer.stats.shotsOffTarget}
                  </FirstPlayerNumber>
                </FirstPlayerData>
                <SecondPlayerData>
                  <SecondPlayerLine
                    value={
                      selectedPlayer && selectedPlayer.stats.shotsOffTarget
                    }
                  />
                  <SecondPlayerNumber>
                    {selectedPlayer && selectedPlayer.stats.shotsOffTarget}
                  </SecondPlayerNumber>
                </SecondPlayerData>
              </StatIndex>
            </StatWrapper>

            <StatWrapper>
              <StatName>BLOCKED KICKS</StatName>
              <StatIndex>
                <FirstPlayerData>
                  <FirstPlayerLine value={firstPlayer.stats.blockedKicks} />
                  <FirstPlayerNumber>
                    {firstPlayer.stats.blockedKicks}
                  </FirstPlayerNumber>
                </FirstPlayerData>
                <SecondPlayerData>
                  <SecondPlayerLine
                    value={selectedPlayer && selectedPlayer.stats.blockedKicks}
                  />
                  <SecondPlayerNumber>
                    {selectedPlayer && selectedPlayer.stats.blockedKicks}
                  </SecondPlayerNumber>
                </SecondPlayerData>
              </StatIndex>
            </StatWrapper>

            <StatWrapper>
              <StatName>POSESSION</StatName>
              <StatIndex>
                <FirstPlayerData>
                  <FirstPlayerLine value={firstPlayer.stats.posession} />
                  <FirstPlayerNumber>
                    {firstPlayer.stats.posession}
                  </FirstPlayerNumber>
                </FirstPlayerData>
                <SecondPlayerData>
                  <SecondPlayerLine
                    value={selectedPlayer && selectedPlayer.stats.posession}
                  />
                  <SecondPlayerNumber>
                    {selectedPlayer && selectedPlayer.stats.posession}
                  </SecondPlayerNumber>
                </SecondPlayerData>
              </StatIndex>
            </StatWrapper>

            <StatWrapper>
              <StatName>CORNER KICKS</StatName>
              <StatIndex>
                <FirstPlayerData>
                  <FirstPlayerLine value={firstPlayer.stats.cornerKicks} />
                  <FirstPlayerNumber>
                    {firstPlayer.stats.cornerKicks}
                  </FirstPlayerNumber>
                </FirstPlayerData>
                <SecondPlayerData>
                  <SecondPlayerLine
                    value={selectedPlayer && selectedPlayer.stats.cornerKicks}
                  />
                  <SecondPlayerNumber>
                    {selectedPlayer && selectedPlayer.stats.cornerKicks}
                  </SecondPlayerNumber>
                </SecondPlayerData>
              </StatIndex>
            </StatWrapper>

            <StatWrapper>
              <StatName>FOULS</StatName>
              <StatIndex>
                <FirstPlayerData>
                  <FirstPlayerLine value={firstPlayer.stats.fouls} />
                  <FirstPlayerNumber>
                    {firstPlayer.stats.fouls}
                  </FirstPlayerNumber>
                </FirstPlayerData>
                <SecondPlayerData>
                  <SecondPlayerLine
                    value={selectedPlayer && selectedPlayer.stats.fouls}
                  />
                  <SecondPlayerNumber>
                    {selectedPlayer && selectedPlayer.stats.fouls}
                  </SecondPlayerNumber>
                </SecondPlayerData>
              </StatIndex>
            </StatWrapper>
          </PlayerStatsWrapper>
        </PlayerComparisonWrapper>
        <PlayersWrapper ref={playersRef}>{renderPlayers()}</PlayersWrapper>
      </PageContainer>
      <Footer>
        <NextButton
          type="primary"
          block
          disabled={!selectedPlayer}
          onClick={onClickNext}
        >
          NEXT
        </NextButton>
      </Footer>
    </AppContainer>
  );
};

export default withRouter(ReplayPage);
