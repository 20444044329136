import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import map from "lodash/map";
import filter from "lodash/filter";
import Button from "shared/components/Button";

import Header from "features/header";
import snapifyIcon from "shared/assets/icons/Snapify_Logo@2x.png";
import footballIcon from "shared/assets/icons/football.png";
import baseballIcon from "shared/assets/icons/baseball.png";
import basketballIcon from "shared/assets/icons/basketball.png";
import EPL from "shared/assets/icons/EPLleague.png";
import UCL from "shared/assets/icons/UCL.png";
import LALIGA from "shared/assets/icons/laliga.png";
import teamIcon from "shared/assets/icons/team.png";

const sports = [
  {
    id: 1,
    name: "FOOTBALL",
    icon: footballIcon
  },
  {
    id: 2,
    name: "BASEBALL",
    icon: baseballIcon
  },
  {
    id: 3,
    name: "BASKETBALL",
    icon: basketballIcon
  }
];

const leagues = [
  {
    id: 11,
    name: "LA LIGA",
    icon: LALIGA,
    sport_id: 1
  },
  {
    id: 12,
    name: "EPL",
    icon: EPL,
    sport_id: 1
  },
  {
    id: 13,
    name: "UCL",
    icon: UCL,
    sport_id: 1
  }
];

const teams = [
  {
    id: 1,
    name: "AJAX",
    icon: teamIcon,
    league_id: 11
  },
  {
    id: 2,
    name: "AJAX",
    icon: teamIcon,
    league_id: 11
  },
  {
    id: 3,
    name: "AJAX",
    icon: teamIcon,
    league_id: 11
  },
  {
    id: 4,
    name: "AJAX",
    icon: teamIcon,
    league_id: 11
  },
  {
    id: 5,
    name: "AJAX",
    icon: teamIcon,
    league_id: 11
  }
];

const AppContainer = styled.div`
  height: calc(100vh - 110px);
  background: rgba(0,0,0,0.9);
  background-image: url("${require("shared/assets/icons/BG.png")}");
  background-size: cover;
  background-position: center top;
  overflow-x: scroll;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const Footer = styled.div`
  height: 50px;
`;

const TitleSection = styled.div`
  margin: 20px 0;
`;
const MainTitle = styled.h2`
  font-family: Druk;
  color: white !important;
  margin: 0;
`;
const SubTitle = styled.h2`
  color: white !important;
  margin: 0;
  font-size: 14px;
  font-family: ProximaNova;
  font-weight: bold;
`;

const Card = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(175, 175, 175, 1);
`;
const ItemTitle = styled.h2`
  color: white;
  margin: 0;
  font-size: 14px;
  font-family: ProximaNova;
  font-weight: bold;
  width: 100px;
  text-align: left;
`;
const ItemImage = styled.img`
  width: 80px;
  height: auto;
`;
const ItemButton = styled(Button)`
  width: 100px;
  font-size: 12px;
`;

const ClubsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
`;
const ClubCard = styled.div`
  width: calc(50% - 20px);
  background: rgba(255, 255, 255, 0.16);
  padding: 10px 0;
  margin: 20px 10px;
  box-sizing: border-box;
`;
const ClubImage = styled.img`
  width: 80px;
  height: auto;
  margin-bottom: 16px;
`;

const NewPage = ({ history }) => {
  const [selectedSports, setSelectedSports] = useState([]);
  const [selectedLeagues, setSelectedLeagues] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [currentTab, setCurrentTab] = useState("sports");

  const renderSportsSection = () => {
    const handleSelectedSports = sportId => {
      setSelectedSports([...selectedSports, sportId]);
    };
    const renderSports = () => {
      return map(sports, sport => {
        return (
          <Card>
            <ItemTitle>{sport.name}</ItemTitle>
            <ItemImage src={sport.icon} alt="" />
            <ItemButton
              disabled={selectedSports.includes(sport.id)}
              onClick={() => handleSelectedSports(sport.id)}
            >
              {selectedSports.includes(sport.id) ? "FOLLOWING" : "FOLLOW"}
            </ItemButton>
          </Card>
        );
      });
    };
    return (
      <React.Fragment>
        <AppContainer>
          <TitleSection>
            <MainTitle>SPORTS</MainTitle>
            <SubTitle>Pick sports that you follow</SubTitle>
          </TitleSection>
          {renderSports()}
        </AppContainer>
        <Footer>
          <Button
            disabled={selectedSports.length === 0}
            type="primary"
            block
            style={{ height: "100%" }}
            onClick={() => setCurrentTab("leagues")}
          >
            NEXT
          </Button>
        </Footer>
      </React.Fragment>
    );
  };

  const renderLeaguesSection = () => {
    const handleSelectedLeagues = leagueId => {
      setSelectedLeagues([...selectedLeagues, leagueId]);
    };
    const renderLeagues = () => {
      const filteredLeagues = filter(leagues, league =>
        selectedSports.includes(league.sport_id)
      );
      return map(filteredLeagues, league => {
        return (
          <Card>
            <ItemTitle>{league.name}</ItemTitle>
            <ItemImage src={league.icon} alt="" />
            <ItemButton
              disabled={selectedLeagues.includes(league.id)}
              onClick={() => handleSelectedLeagues(league.id)}
            >
              {selectedLeagues.includes(league.id) ? "FOLLOWING" : "FOLLOW"}
            </ItemButton>
          </Card>
        );
      });
    };
    return (
      <React.Fragment>
        <AppContainer>
          <TitleSection>
            <MainTitle>LEAGUES</MainTitle>
            <SubTitle>Pick your favourite leagues</SubTitle>
          </TitleSection>
          {renderLeagues()}
        </AppContainer>
        <Footer>
          <Button
            disabled={selectedLeagues.length === 0}
            type="primary"
            block
            style={{ height: "100%" }}
            onClick={() => setCurrentTab("teams")}
          >
            NEXT
          </Button>
        </Footer>
      </React.Fragment>
    );
  };

  const renderTeamsSection = () => {
    const handleSelectedTeams = teamId => {
      setSelectedTeams([...selectedTeams, teamId]);
    };
    const renderTeams = () => {
      const filteredTeams = filter(teams, team =>
        selectedLeagues.includes(team.league_id)
      );
      return map(filteredTeams, team => {
        return (
          <ClubCard>
            <ClubImage src={team.icon} />
            <ItemButton
              disabled={selectedTeams.includes(team.id)}
              onClick={() => handleSelectedTeams(team.id)}
            >
              {selectedTeams.includes(team.id) ? "FOLLOWING" : "FOLLOW"}
            </ItemButton>
          </ClubCard>
        );
      });
    };
    return (
      <React.Fragment>
        <AppContainer>
          <TitleSection>
            <MainTitle>CLUB</MainTitle>
            <SubTitle>Pick your favourite club(s)</SubTitle>
          </TitleSection>
          <ClubsSection>{renderTeams()}</ClubsSection>
        </AppContainer>
        <Footer>
          <Button
            disabled={selectedTeams.length === 0}
            type="primary"
            block
            style={{ height: "100%" }}
            onClick={() => {
              history.push("/app/feeds");
            }}
          >
            NEXT
          </Button>
        </Footer>
      </React.Fragment>
    );
  };

  const renderDetailsSection = () => {
    if (currentTab === "sports") {
      return renderSportsSection();
    } else if (currentTab === "leagues") {
      return renderLeaguesSection();
    } else if (currentTab === "teams") {
      return renderTeamsSection();
    }
  };

  return (
    <div style={{ height: "calc(100vh)" }}>
      <Header
        leftSection={<img src={snapifyIcon} alt="" width="30px" />}
        title="CREATE ACCOUNT"
      />
      {renderDetailsSection()}
    </div>
  );
};

export default withRouter(NewPage);
