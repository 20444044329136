import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  background: #090e2e;
  padding: 15px;
  height: 60px;
  align-items: center;
`;
const LeftSection = styled.div`
  width: 40px;
`;
const Title = styled.h2`
  font-family: ProximaNova;
  color: white;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  text-align: center;
`;
const RightSection = styled.div`
  width: 40px;
`;

const Header = ({ title, leftSection, rightSection }) => {
  return (
    <Wrapper>
      <LeftSection>{leftSection}</LeftSection>
      <Title>{title}</Title>
      <RightSection>{rightSection}</RightSection>
    </Wrapper>
  );
};

export default Header;
