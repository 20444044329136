import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import NewPage from "./pages/new";
import FeedsPage from "./pages/feeds";
import RecreatePage from "./pages/recreate";
import ReplayPage from "./pages/replay";
import FinalPage from "./pages/final";

const MainApp = () => {
  let match = useRouteMatch();

  return (
      <Switch>
        <Route path={`${match.path}/new`} component={NewPage} />
        <Route path={`${match.path}/feeds`} component={FeedsPage} />
        <Route path={`${match.path}/recreate`} component={RecreatePage} />
        <Route path={`${match.path}/replay`} component={ReplayPage} />
        <Route path={`${match.path}/final`} component={FinalPage} />
      </Switch>
  );
};

export default MainApp;