import { Button } from "antd";
import styled from "styled-components";

const getBackground = props => {
  if (props.background) {
    return props.background;
  } else if (props.disabled) {
    return "#0c9378";
  }
  return "#0ce6bd";
};

const StyledButton = styled(Button)`
  background-color: ${props => getBackground(props)} !important;
  border-color: ${props => (props.disabled ? "#0c9378" : "#0ce6bd")} !important;
  color: black !important;
  margin: 0 !important;
  font-family: ProximaNova !important;
  font-weight: bold !important;

  &:hover {
    background-color: ${props =>
      props.disabled ? "#0c9378" : "#0ce6bd"} !important;
    border-color: ${props =>
      props.disabled ? "#0c9378" : "#0ce6bd"} !important;
    color: black !important;
    font-family: ProximaNova !important;
    font-weight: bold !important;
  }
`;

export default StyledButton;
