import React, { Component } from "react";
import styled from "styled-components";
import { Player, BigPlayButton } from "video-react";

import footballLogo from "shared/assets/icons/football_small.png";
import leagueLogo from "shared/assets/icons/EPL.png";
import stat1Logo from "shared/assets/icons/football-pitch-ball@2x.png";
import stat2Logo from "shared/assets/icons/goalie@2x.png";
import teamLogo from "shared/assets/icons/team.png";
const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const TopOverlayWrapper = styled.div`
  display: ${props =>
    props.playerState && props.playerState.paused ? "flex" : "none"};
  background: rgba(0, 0, 0, 0.45);
  position: absolute;
  width: 100%;
  z-index: 1;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
`;
const TopOverlayLeftSection = styled.div`
  display: flex;
  align-items: center;
`;
const TopOverlayRightSection = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.h2`
  color: white;
  font-size: 10px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
const LeagueLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;
const EventLogo = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 10px;
`;
const EventTime = styled.h2`
  color: white;
  border: 1px solid white;
  border-radius: 100%;
  font-size: 10px;
  padding: 2px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
const ActorName = styled.h2`
  color: white;
  font-size: 10px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;

const BottomOverlayWrapper = styled.div`
  display: ${props =>
    props.playerState && props.playerState.paused ? "flex" : "none"};
  background: rgba(0, 0, 0, 0.45);
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  top: 158px;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const BottomOverlayLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BottomOverlayRightSection = styled.div`
  display: flex;
  align-items: center;
`;
const EventDate = styled.h2`
  color: white;
  font-size: 10px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
const EventDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TeamLogo = styled.img`
  width: 20px;
  height: 20px;
`;
const EventDetail = styled.h2`
  color: white;
  font-size: 10px;
  margin: 0 10px;
  font-family: Druk;
`;
const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
`;
const StatsImage = styled.img`
  width: 15px;
  height: 15px;
`;
const StatsTitle = styled.h2`
  color: white;
  font-size: 8px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
const StatsNumber = styled.h2`
  color: white;
  font-family: Druk;
  font-size: 10px;
  letter-spacing: 1px;
  margin: 0;
`;

export default class VideoPlayer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  handleStateChange(state) {
    this.setState({
      player: state
    });
  }

  render() {
    return (
      <PlayerWrapper>
        <TopOverlayWrapper playerState={this.state.player}>
          <TopOverlayLeftSection>
            <LeagueLogo src={leagueLogo} />
            <Title>ON THIS DAY</Title>
          </TopOverlayLeftSection>
          <TopOverlayRightSection>
            <EventTime>89'</EventTime>
            <EventLogo src={footballLogo} />
            <ActorName>Jesus</ActorName>
          </TopOverlayRightSection>
        </TopOverlayWrapper>
        <BottomOverlayWrapper playerState={this.state.player}>
          <BottomOverlayLeftSection>
            <EventDate>30 APRIL 1978</EventDate>
            <EventDetailsWrapper>
              <TeamLogo src={teamLogo} />
              <EventDetail>3-1</EventDetail>
              <TeamLogo src={teamLogo} />
            </EventDetailsWrapper>
          </BottomOverlayLeftSection>
          <BottomOverlayRightSection>
            <StatsWrapper>
              <StatsImage src={stat1Logo} />
              <StatsTitle>REPLAYED</StatsTitle>
              <StatsNumber>2K+</StatsNumber>
            </StatsWrapper>
            <StatsWrapper>
              <StatsImage src={stat2Logo} />
              <StatsTitle>CHANGED</StatsTitle>
              <StatsNumber>567</StatsNumber>
            </StatsWrapper>
          </BottomOverlayRightSection>
        </BottomOverlayWrapper>
        <Player
          src={this.props.videoURL}
          poster="https://m.hindustantimes.com/rf/image_size_444x250/HT/p2/2020/01/03/Pictures/barcelona-final-liverpool-first-champions-league-semi_db704ac2-2e0a-11ea-b337-29936d1a9c86.jpg"
          ref={player => {
            this.player = player;
          }}
        >
          <BigPlayButton className="123" position="center" />
        </Player>
      </PlayerWrapper>
    );
  }
}
