import { Slider } from "antd";
import styled from "styled-components";

const StyledSlider = styled(Slider)`
  .ant-slider-track {
    background: #0ce6bd !important;
  }
  .ant-slider-track {
    height: 10px !important;
  }
  .ant-slider-rail {
    height: 10px !important;
  }
  .ant-slider-handle {
    height: 20px;
    width: 20px;
    border-color: black;
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: black;
  }
  .ant-slider-dot {
    top: 1px;
    height: 8px;
    width: 8px;
    background: black;
    border-color: black;
  }
`;

export default StyledSlider;
