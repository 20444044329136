import React, { Component } from "react";
import styled from "styled-components";
import { Player, ControlBar, BigPlayButton } from "video-react";
import Slider from "shared/components/Slider";
import playerPosition1 from "shared/assets/icons/playerpositions1.png";
import playerPosition2 from "shared/assets/icons/playerpositions2.png";
import playerPosition3 from "shared/assets/icons/playerpositions3.png";
import playerPosition4 from "shared/assets/icons/playerpositions4.png";
import playerPosition5 from "shared/assets/icons/playerpositions5.png";

const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
`;
export default class VideoPlayer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      slider: 80,
      currentPosition: 4,
      playerPositions: [playerPosition1, playerPosition2, playerPosition3, playerPosition4, playerPosition5],
    };
  }

  componentDidMount() {
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  handleStateChange(state) {
    this.setState({
      player: state
    });
  }

  handleChange = (value) => {
    if (value > 20) {
      this.setState({
        slider: value
      });
      this.player.seek(Math.round((100 - this.state.player.duration)*value/100));
      this.player.pause();
      this.setState({
        currentPosition: Math.floor(value/20)
      })
    }
  }

  render() {
    return (
      <PlayerWrapper>
        <div style={{position:'absolute', width: '100%', height: '230px', zIndex: 1, background: 'rgba(0, 0, 0, 0.25)'}}>
        <img
          src={this.state.playerPositions[this.state.currentPosition - 1]}
          style={{
            width: "100%",
            height: "245px",
            marginTop: '-15px'
          }}
          alt=""
        />
        </div>
        <Player
          ref={player => {
            this.player = player;
          }}
          fluid={false}
          height={230}
          startTime={20}
          width="100%"
        >
          <source src="https://snapify-replay-bucket.s3-eu-west-1.amazonaws.com/Replay_001.mp4" />
          <BigPlayButton className="videoButton" position="center" />
          <ControlBar disableCompletely={true} />
        </Player>
        <Slider value={this.state.slider} reverse={true} tooltipVisible={false} marks={{20: ''}} onChange={this.handleChange} />
      </PlayerWrapper>
    );
  }
}
