import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import {
  MenuOutlined,
  UserOutlined,
  VideoCameraOutlined,
  SettingOutlined,
  CompassOutlined
} from "@ant-design/icons";
import { Drawer } from "antd";

import VideoPlayer from "./VideoPlayer";
import Header from "features/header";
import Button from "shared/components/Button";
import snapifyIcon from "shared/assets/icons/Snapify_Logo@2x.png";
import socialIcon from "shared/assets/icons/social.png";
import userIcon from "shared/assets/icons/user.png";

const AppContainer = styled.div`
  height: calc(100vh);
  width: 100%;
  background-color: #090e2e;
  background-image: url("${require("shared/assets/icons/BG.png")}");
  background-size: cover;
  background-position: center top;
`;
const PageContainer = styled.div`
  height: calc(100vh - 60px);
  overflow-x: scroll;
  padding: 20px;
`;
const FeedCard = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
`;
const RecreateButton = styled(Button)`
  font-size: 10px;
`;

const DrawerContainer = styled.div`
background-color: #090e2e;
background-image: url("${require("shared/assets/icons/BG.png")}");
background-size: cover;
    background-position: center top;
    padding: 25px;
height: 100vh;
box-sizing: border-box;
`;
const DrawerHeader = styled.div`
  height: 50px;
  box-sizing: border-box;
`;
const DrawerContent = styled.div`
  height: calc(100vh - 170px);
  box-sizing: border-box;
`;
const DrawerFooter = styled.div`
  height: 50px;
  box-sizing: border-box;
`;
const LogoContainer = styled.div`
  display: flex;
`;
const Logo = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 20px;
`;
const AppName = styled.h2`
  margin: 0;
  font-size: 15px;
  color: white;
`;

const UserContainer = styled.div`
  margin-top: 30px;
  margin-left: 10px;
  display: flex;
`;
const UserIcon = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 30px;
`;
const UserDetailsContainer = styled.div`
  display: flex;

  flex-direction: column;
`;
const UserName = styled.h2`
  font-family: Druk;
  font-size: 15px;
  color: white;
  margin: 0;
`;
const UserDescription = styled.h2`
  font-size: 14px;
  color: white;
  margin: 0;
`;
const NavigationList = styled.ul`
  margin-top: 40px;
  margin-left: 10px;
  list-style: none;
  padding: 0;
`;
const NavigationListItem = styled.li`
  margin-top: 20px;
`;
const NavigationText = styled.span`
  color: white;
  font-size: 15px;
  margin-left: 20px;
`;
const SocialIconsContainer = styled.div`
  margin: 0 10px;
`;
const SocailIcon = styled.img`
  width: 100%;
`;

const FeedsPage = ({ history }) => {
  const [visible, setVisibility] = useState(false);
  const onClickRecreate = () => {
    history.push("/app/recreate");
  };
  const renderFeed = (videoURL) => {
    return (
      <FeedCard>
        <VideoPlayer videoURL={videoURL} />
        <RecreateButton type="primary" block onClick={onClickRecreate}>
          RE-CREATE HISTORY
        </RecreateButton>
      </FeedCard>
    );
  };

  return (
    <AppContainer>
      <Header
        leftSection={
          <MenuOutlined
            style={{ color: "#0ce6bd", fontSize: "16px" }}
            onClick={() => {
              setVisibility(true);
            }}
          />
        }
        title="EXPLORE"
      />
      <PageContainer>
        {renderFeed("https://snapify-replay-bucket.s3-eu-west-1.amazonaws.com/Replay_001.mp4")}
        {renderFeed("https://snapify-replay-bucket.s3-eu-west-1.amazonaws.com/Replay_002.mp4")}
        {renderFeed("https://snapify-replay-bucket.s3-eu-west-1.amazonaws.com/Replay_003.mp4")}
        {renderFeed("https://snapify-replay-bucket.s3-eu-west-1.amazonaws.com/Replay_003.mp4")}
      </PageContainer>
      <Drawer
        placement="left"
        closable={false}
        onClose={() => {
          setVisibility(false);
        }}
        visible={visible}
      >
        <DrawerContainer>
          <DrawerHeader>
            <LogoContainer>
              <Logo src={snapifyIcon} />
              <AppName>REPLAY THE HISTORY</AppName>
            </LogoContainer>
          </DrawerHeader>
          <DrawerContent>
            <UserContainer>
              <UserIcon src={userIcon} />
              <UserDetailsContainer>
                <UserName>VOHLA</UserName>
                <UserDescription>MCFC</UserDescription>
              </UserDetailsContainer>
            </UserContainer>
            <NavigationList>
              <NavigationListItem>
                <UserOutlined style={{ color: "white", fontSize: "15px" }} />
                <NavigationText>ACCOUNT</NavigationText>
              </NavigationListItem>
              <NavigationListItem>
                <VideoCameraOutlined
                  style={{ color: "white", fontSize: "15px" }}
                />
                <NavigationText>VIDEOS</NavigationText>
              </NavigationListItem>
              <NavigationListItem>
                <CompassOutlined style={{ color: "white", fontSize: "15px" }} />
                <NavigationText>EXPLORE</NavigationText>
              </NavigationListItem>
              <NavigationListItem>
                <SettingOutlined style={{ color: "white", fontSize: "15px" }} />
                <NavigationText>SETTINGS</NavigationText>
              </NavigationListItem>
            </NavigationList>
          </DrawerContent>
          <DrawerFooter>
            <SocialIconsContainer>
              <SocailIcon src={socialIcon} />
            </SocialIconsContainer>
          </DrawerFooter>
        </DrawerContainer>
      </Drawer>
    </AppContainer>
  );
};

export default withRouter(FeedsPage);
