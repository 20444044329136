import React, { useEffect } from "react";
import {
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import MainApp from "./MainApp";

const Routes = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === "/") {
      history.push("/app/new");
    }
  }, [location, history]);

  return (
    <Switch>
      <Route path="/app" component={MainApp} />
    </Switch>
  );
};

export default Routes;