import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";
import find from "lodash/find";

import Header from "features/header";
import footballLogo from "shared/assets/icons/football_small.png";
import teamLogo from "shared/assets/icons/team.png";
import player1Logo from "shared/assets/icons/anderson.png";
import player2Logo from "shared/assets/icons/zabaleta.png";
import player3Logo from "shared/assets/icons/gabriel.png";
import player4Logo from "shared/assets/icons/sterling.png";
import player5Logo from "shared/assets/icons/bravo.png";
import thumbnailLogo from "shared/assets/icons/video.png";
import changeIcon from "shared/assets/icons/change.png";
import EPL from "shared/assets/icons/EPL.png";
import refreshIcon from "shared/assets/icons/refresh.png";
import stat1Icon from "shared/assets/icons/football-pitch-ball@2x.png";
import stat2Icon from "shared/assets/icons/goalie@2x.png";
import stat3Icon from "shared/assets/icons/jersey.png";
import twitterIcon from "shared/assets/icons/twitter.png";
import facebookIcon from "shared/assets/icons/facebook.png";
import instagramIcon from "shared/assets/icons/instagram.png";
import webIcon from "shared/assets/icons/web.png";

const AppContainer = styled.div`
  height: calc(100vh);
  width: 100%;
  background: rgba(0,0,0,0.8);
  background-image: url("${require("shared/assets/icons/BG.png")}");
  background-size: cover;
  background-position: center top;
`;
const PageContainer = styled.div`
  height: calc(100vh - 60px);
  overflow-x: scroll;
  padding: 20px;
`;
const DetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
`;
const DetailsRightSection = styled.div`
  display: flex;
  align-items: center;
`;
const EventLogo = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 10px;
`;
const EventTime = styled.h2`
  color: white;
  border: 1px solid white;
  border-radius: 100%;
  font-size: 12px;
  padding: 2px;
  margin: 0;
`;
const ActorName = styled.h2`
  color: white;
  font-size: 12px;
  margin: 0;
`;

const DetailsLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const EventDate = styled.h2`
  color: white;
  font-size: 12px;
  margin: 0;
`;
const EventDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TeamLogo = styled.img`
  width: 20px;
  height: 20px;
`;
const EventDetail = styled.h2`
  color: white;
  font-size: 12px;
  font-family: Druk;
  margin: 0 10px;
`;

const VideoCard = styled.div`
background: rgba(9, 14, 46, 1);
`;
const TopOverlayWrapper = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.15);
  width: 100%;
  height: 70px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;
const LeagueDetails = styled.div`
  display: flex;
`;
const LeagueLogo = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 20px;
`;
const OverlayTitle = styled.h2`
  font-size: 12px;
  color: white;
  margin: 0;
  line-height: 2;
`;
const PlayerDetails = styled.div`
  display: flex;
  align-items: center;
`;
const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PlayerImage = styled.img`
  width: 35px;
  height: 35px;
`;
const PlayerName = styled.div`
  font-size: 8px;
  color: white;
`;
const SwapIcon = styled.img`
  height: 15px;
  width: 15px;
  margin: 0 10px;
`;
const BottomOverlayWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 250px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const LoadingLogo = styled.img`
  width: 50px;
  height: 50px;
`;
const LoadingTitle = styled.h2`
  font-size: 10px;
  color: white;
  margin: 0;
`;
const ThumbnailWrapper = styled.div`
  width: 100%;
  height: 250px;
  position: relative;
`;
const Thumbnail = styled.img`
  position: relative;
  width: 100%;
  height: 250px;
`;
const VideoCardFooter = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px;
`;
const SocialIcon = styled.img`
  width: auto;
  height: 20px;
`;
const StatsCard = styled.div`
  margin-top: 20px;
  padding: 20px;
  background: rgba(9, 14, 46, 1);
`;
const StatsTitle = styled.h2`
  font-size: 12px;
  letter-spacing: 1px;
  color: white;
  font-family: Druk;
  margin: 0 0 30px;
`;
const StatDetailWrapper = styled.div`
display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const StatImage = styled.img`
  width: 20px;
  height: 20px;
`;
const StatName = styled.h2`
  font-size: 10px;
  color: white;
  margin: 0 0 5px;
`;
const StatNumber = styled.h2`
  font-size: 10px;
  color: white;
  margin: 5px 0 0;
  font-family: Druk;
`;
const StatsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const MultiStatWrapper = styled.div`
  display: flex;
`;

const players = [
  {
    id: 1,
    name: "BRAVO",
    number: "1",
    style: "Goalkeeper",
    image: player1Logo,
    teamColor: "rgba(59, 190, 229, 1)",
    stats: {
      shotsOnTarget: 43,
      shotsOffTarget: 68,
      blockedKicks: 22,
      posession: 89,
      cornerKicks: 4,
      fouls: 10
    }
  },
  {
    id: 2,
    name: "STERLING",
    number: "7",
    style: "Forward",
    image: player2Logo,
    teamColor: "rgba(59, 190, 229, 1)",
    stats: {
      shotsOnTarget: 22,
      shotsOffTarget: 80,
      blockedKicks: 70,
      posession: 55,
      cornerKicks: 87,
      fouls: 18
    }
  },
  {
    id: 3,
    name: "JESUS",
    number: "9",
    style: "Forward",
    image: player3Logo,
    teamColor: "rgba(59, 190, 229, 1)",
    stats: {
      shotsOnTarget: 25,
      shotsOffTarget: 65,
      blockedKicks: 87,
      posession: 46,
      cornerKicks: 90,
      fouls: 23
    }
  },
  {
    id: 4,
    name: "ZABALETA",
    number: "5",
    style: "Defender",
    image: player4Logo,
    teamColor: "rgba(124, 44, 59, 1)",
    stats: {
      shotsOnTarget: 85,
      shotsOffTarget: 12,
      blockedKicks: 43,
      posession: 53,
      cornerKicks: 56,
      fouls: 87
    }
  },
  {
    id: 5,
    name: "ANDERSON",
    number: "8",
    style: "Midfielder",
    image: player5Logo,
    teamColor: "rgba(124, 44, 59, 1)",
    stats: {
      shotsOnTarget: 12,
      shotsOffTarget: 23,
      blockedKicks: 87,
      posession: 43,
      cornerKicks: 59,
      fouls: 32
    }
  }
];

const FinalPage = ({ history, location }) => {
  const firstPlayer = location.state.firstPlayer;
  const secondPlayer = location.state.secondPlayer;

  return (
    <AppContainer>
      <Header
        leftSection={
          <ArrowLeftOutlined
            style={{ color: "#0ce6bd", fontSize: "16px" }}
            onClick={() => {
              history.push("/app/recreate");
            }}
          />
        }
        title="REPLAY THE HISTORY"
      />
      <PageContainer>
        <DetailsWrapper>
          <DetailsLeftSection>
            <EventDate>30 APRIL 1978</EventDate>
            <EventDetailsWrapper>
              <TeamLogo src={teamLogo} />
              <EventDetail>3 - 1</EventDetail>
              <TeamLogo src={teamLogo} />
            </EventDetailsWrapper>
          </DetailsLeftSection>
          <DetailsRightSection>
            <EventTime>89'</EventTime>
            <EventLogo src={footballLogo} />
            <ActorName>Jesus</ActorName>
          </DetailsRightSection>
        </DetailsWrapper>
        <VideoCard>
          <ThumbnailWrapper>
            <TopOverlayWrapper>
              <LeagueDetails>
                <LeagueLogo src={EPL} />
                <OverlayTitle>45s highlight</OverlayTitle>
              </LeagueDetails>
              <PlayerDetails>
                <PlayerWrapper>
                  <PlayerImage src={firstPlayer.image} />
                  <PlayerName>{`#${firstPlayer.number} ${firstPlayer.name}`}</PlayerName>
                </PlayerWrapper>
                <SwapIcon src={changeIcon} />
                <PlayerWrapper>
                  <PlayerImage src={secondPlayer.image} />
                  <PlayerName>{`#${secondPlayer.number} ${secondPlayer.name}`}</PlayerName>
                </PlayerWrapper>
              </PlayerDetails>
            </TopOverlayWrapper>
            <BottomOverlayWrapper>
              <LoadingLogo src={refreshIcon} />
              <LoadingTitle>
                Just a minute… your video is being generated
              </LoadingTitle>
            </BottomOverlayWrapper>
            <Thumbnail src={thumbnailLogo} />
          </ThumbnailWrapper>
          <VideoCardFooter>
            <SocialIcon src={instagramIcon} />
            <SocialIcon src={twitterIcon} />
            <SocialIcon src={facebookIcon} />
            <SocialIcon src={webIcon} />
          </VideoCardFooter>
        </VideoCard>
        <StatsCard>
          <StatsTitle>THIS VIDEO IN THE PAST</StatsTitle>
          <StatsWrapper>
            <StatWrapper>
              <StatName>REPLAYED</StatName>
              <StatDetailWrapper>
                <StatImage src={stat1Icon} />
                <StatNumber>2K+</StatNumber>
              </StatDetailWrapper>
            </StatWrapper>
            <StatWrapper>
              <StatName>CHANGED</StatName>
              <StatDetailWrapper>
                <StatImage src={stat2Icon} />
                <StatNumber>567</StatNumber>
              </StatDetailWrapper>
            </StatWrapper>
            <StatWrapper>
              <StatName>MOST OFTEN</StatName>
              <MultiStatWrapper>
                <StatDetailWrapper>
                  <StatImage src={stat3Icon} />
                  <StatNumber>Messi</StatNumber>
                </StatDetailWrapper>
                <SwapIcon src={changeIcon} />
                <StatDetailWrapper>
                  <StatImage src={stat3Icon} />
                  <StatNumber>Ronaldo</StatNumber>
                </StatDetailWrapper>
              </MultiStatWrapper>
            </StatWrapper>
          </StatsWrapper>
        </StatsCard>
      </PageContainer>
    </AppContainer>
  );
};

export default withRouter(FinalPage);
