import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";
import map from "lodash/map";

import VideoPlayer from "./VideoPlayer";
import Header from "features/header";
import Button from "shared/components/Button";
import footballLogo from "shared/assets/icons/football_small.png";
import teamLogo from "shared/assets/icons/team.png";
import player1Logo from "shared/assets/icons/anderson.png";
import player2Logo from "shared/assets/icons/zabaleta.png";
import player3Logo from "shared/assets/icons/gabriel.png";
import player4Logo from "shared/assets/icons/sterling.png";
import player5Logo from "shared/assets/icons/bravo.png";

const AppContainer = styled.div`
  height: calc(100vh);
  width: 100%;
  background: rgba(0,0,0,0.8);
  background-image: url("${require("shared/assets/icons/BG.png")}");
  background-size: cover;
  background-position: center top;
`;
const PageContainer = styled.div`
  height: calc(100vh - 110px);
  overflow-x: scroll;
  padding: 20px;
`;
const Footer = styled.div`
  height: 50px;
`;
const DetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
`;
const DetailsRightSection = styled.div`
  display: flex;
  align-items: center;
`;
const EventLogo = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 10px;
`;
const EventTime = styled.h2`
  color: white;
  border: 1px solid white;
  border-radius: 100%;
  font-size: 12px;
  padding: 2px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
const ActorName = styled.h2`
  color: white;
  font-size: 12px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;

const DetailsLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const EventDate = styled.h2`
  color: white;
  font-size: 12px;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
const EventDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TeamLogo = styled.img`
  width: 20px;
  height: 20px;
`;
const EventDetail = styled.h2`
  color: white;
  font-size: 12px;
  font-family: Druk;
  margin: 0 10px;
`;

const PlayersWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-top: 40px;
  align-items: baseline;
  -webkit-overflow-scrolling: touch;
`;
const PlayerWrapper = styled.div`
  flex: 0 0 auto;
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PlayerName = styled.h2`
  color: white;
  font-size: 10px !important;
  margin: 5px 0 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
const PlayingStyle = styled.h2`
  color: white;
  font-size: 8px !important;
  margin: 0;
  font-family: ProximaNova;
  font-weight: bold;
`;
const PlayerImageWrapper = styled.div`
  background: ${props => props.teamColor || "rgba(59, 190, 229, 1)"} !important;
  border-radius: 100%;
  width: ${props => (props.active ? "70px" : "40px")} !important;
  height: ${props => (props.active ? "70px" : "40px")} !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.3s, height 0.3s;
  transition-timing-function: linear;
`;
const PlayerImage = styled.img`
  width: ${props => (props.active ? "50px" : "30px")} !important;
  height: ${props => (props.active ? "50px" : "30px")} !important;
  transition: width 0.3s, height 0.3s;
  transition-timing-function: linear;
`;
const NextButton = styled(Button)`
  height: 100%;
`;

const players = [
  {
    id: 1,
    name: "BRAVO",
    number: "1",
    style: "Goalkeeper",
    image: player1Logo,
    teamColor: "rgba(59, 190, 229, 1)"
  },
  {
    id: 2,
    name: "STERLING",
    number: "7",
    style: "Forward",
    image: player2Logo,
    teamColor: "rgba(59, 190, 229, 1)"
  },
  {
    id: 3,
    name: "JESUS",
    number: "9",
    style: "Forward",
    image: player3Logo,
    teamColor: "rgba(59, 190, 229, 1)"
  },
  {
    id: 4,
    name: "ZABALETA",
    number: "5",
    style: "Defender",
    image: player4Logo,
    teamColor: "rgba(124, 44, 59, 1)"
  },
  {
    id: 5,
    name: "ANDERSON",
    number: "8",
    style: "Midfielder",
    image: player5Logo,
    teamColor: "rgba(124, 44, 59, 1)"
  },
  {
    id: 6,
    name: "BRAVO",
    number: "1",
    style: "Goalkeeper",
    image: player1Logo,
    teamColor: "rgba(59, 190, 229, 1)"
  },
  {
    id: 7,
    name: "STERLING",
    number: "7",
    style: "Forward",
    image: player2Logo,
    teamColor: "rgba(59, 190, 229, 1)"
  },
  {
    id: 8,
    name: "JESUS",
    number: "9",
    style: "Forward",
    image: player3Logo,
    teamColor: "rgba(59, 190, 229, 1)"
  },
  {
    id: 9,
    name: "ZABALETA",
    number: "5",
    style: "Defender",
    image: player4Logo,
    teamColor: "rgba(124, 44, 59, 1)"
  },
  {
    id: 10,
    name: "ANDERSON",
    number: "8",
    style: "Midfielder",
    image: player5Logo,
    teamColor: "rgba(124, 44, 59, 1)"
  }
];

const RecreatePage = ({ history }) => {
  const playersRef = useRef();
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const renderPlayers = () => {
    const onClickPlayer = player => {
      const scrollValues = [0, 0, 0, 75, 150, 225, 300, 375, 375, 375];
      playersRef.current.scrollLeft = scrollValues[player.id - 1];
      setSelectedPlayer(player.id);
    };

    return map(players, (player, index) => {
      return (
        <PlayerWrapper key={index} onClick={() => onClickPlayer(player)}>
          <PlayerImageWrapper
            active={selectedPlayer === player.id}
            teamColor={player.teamColor}
          >
            <PlayerImage
              active={selectedPlayer === player.id}
              src={player.image}
            />
          </PlayerImageWrapper>
          <PlayerName
            active={selectedPlayer === player.id}
          >{`#${player.number} ${player.name}`}</PlayerName>
          <PlayingStyle active={selectedPlayer === player.id}>
            {player.style}
          </PlayingStyle>
        </PlayerWrapper>
      );
    });
  };

  const onClickNext = () => {
    history.push({
      pathname: "/app/replay",
      state: { selectedPlayer }
    });
  };

  return (
    <AppContainer>
      <Header
        leftSection={
          <ArrowLeftOutlined
            style={{ color: "#0ce6bd", fontSize: "16px" }}
            onClick={() => {
              history.push("/app/feeds");
            }}
          />
        }
        title="REPLAY THE HISTORY"
      />
      <PageContainer>
        <DetailsWrapper>
          <DetailsLeftSection>
            <EventDate>30 APRIL 1978</EventDate>
            <EventDetailsWrapper>
              <TeamLogo src={teamLogo} />
              <EventDetail>3 - 1</EventDetail>
              <TeamLogo src={teamLogo} />
            </EventDetailsWrapper>
          </DetailsLeftSection>
          <DetailsRightSection>
            <EventTime>89'</EventTime>
            <EventLogo src={footballLogo} />
            <ActorName>Jesus</ActorName>
          </DetailsRightSection>
        </DetailsWrapper>
        <VideoPlayer />
        <PlayersWrapper ref={playersRef}>{renderPlayers()}</PlayersWrapper>
      </PageContainer>
      <Footer>
        <NextButton
          type="primary"
          block
          disabled={!selectedPlayer}
          onClick={onClickNext}
        >
          NEXT
        </NextButton>
      </Footer>
    </AppContainer>
  );
};

export default withRouter(RecreatePage);
